const revalidateAllTag = "all" as const

const revalidateTags = [
  "storyblok",
  "shopify",
  "product",
  "collection",
  "media",
  "vimeo",
  "video",
  "metaobjects",
] as const

export const revalidateTagsValues = getRevalidateTags()

export type RevalidateTag = (typeof revalidateTags)[number] | string

export function getRevalidateTags(): readonly [typeof revalidateAllTag, ...typeof revalidateTags]
export function getRevalidateTags(...tags: RevalidateTag[]): string[]
export function getRevalidateTags(
  tag?: RevalidateTag,
  ...tags: RevalidateTag[]
): string[] | readonly [typeof revalidateAllTag, ...typeof revalidateTags] {
  if (!tag) return [revalidateAllTag, ...revalidateTags] as const

  return [revalidateAllTag, tag, ...tags]
}
